import React from 'react'
import { withRouter } from 'react-router-dom'
import AuthUserContext from './context'
import { withFirebase } from '../firebase'
import MuiLoading from '../ui/MuiLoading'

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: true,
        authUser: null,
      }
    }

    async componentDidMount() {
      const firebase = this.props.firebase
      if (firebase.auth().currentUser) {
        this.setState({ loading: false, authUser: firebase.auth().currentUser })
      } else {
        const authUser = await this._onAuthUser()
        console.log(authUser)
        this.setState({ loading: false, authUser })
      }
    }

    async _onAuthUser() {
      return new Promise(resolve => {
        const unsubscribe = this.props.firebase.auth().onAuthStateChanged(
          authUser => {
            unsubscribe()
            resolve(authUser)
          }
        )
      })
    }

    render() {
      if (this.state.loading) {
        return <MuiLoading />
      }

      if (!this.state.authUser) {
        this.props.history.replace('/signin')
        return null
      }

      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(withRouter(WithAuthentication))
}

export default withAuthentication
