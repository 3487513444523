import * as firebase from "firebase"

const config = {
  apiKey: "AIzaSyA5zdSbEJ4UvtDK48SIzuFkQ-x9ZTnRljA",
  authDomain: "unikang-dawn.firebaseapp.com",
  databaseURL: "https://unikang-dawn.firebaseio.com",
  projectId: "unikang-dawn",
  storageBucket: "unikang-dawn.appspot.com",
  messagingSenderId: "37643370272",
  appId: "1:37643370272:web:c51f8c241a5015843fe145"
}

firebase.initializeApp(config)

export default firebase
