export const SUBMIT_MESSAGE = 'SUBMIT_MESSAGE'
export const SAVING_MESSAGE = 'SAVING_MESSAGE'
export const SAVE_MESSAGE_SUCCESSFUL = 'SAVE_MESSAGE_SUCCESSFUL'
export const SAVE_MESSAGE_FAILED = 'SAVE_MESSAGE_FAILED'
export const CLEAR_MESSAGE_STATE = 'CLEAR_MESSAGE_STATE'

export function submitMessage(recaptchaToken, recaptchaAction, message) {
  return { type: SUBMIT_MESSAGE, recaptchaToken, recaptchaAction, message }
}

export function savingMessage() {
  return { type: SAVING_MESSAGE }
}

export function saveMessageSuccessful() {
  return { type: SAVE_MESSAGE_SUCCESSFUL }
}

export function saveMessageFailed(error) {
  return { type: SAVE_MESSAGE_FAILED, error }
}

export function clearMessageState() {
  return { type: CLEAR_MESSAGE_STATE }
}
