import { call, put, takeEvery } from 'redux-saga/effects'
import firebaseApi from '../../api/firebase'
import {
  FETCH_MESSAGES, DELETE_MESSAGES,
  fetchingMessages, fetchMessagesSuccessful, fetchMessagesFailed,
} from '../actions/messages'

function* fetchMessages() {
  try {
    yield put(fetchingMessages())
    const messages = yield call(firebaseApi.fetchMessages)
    yield put(fetchMessagesSuccessful(messages))
  } catch (error) {
    yield put(fetchMessagesFailed(error.message))
  }
}

function* deleteMessages(action) {
  try {
    const { ids } = action
    yield call(firebaseApi.deleteMessages, ids)
  } catch (error) {
    console.log(`failed to delete messages: ${error}`)
  }
}

export default function* watch() {
  yield takeEvery(FETCH_MESSAGES, fetchMessages)
  yield takeEvery(DELETE_MESSAGES, deleteMessages)
}
