import firebase from '../firebase'

export default {

  signInGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase.auth().useDeviceLanguage()
    return firebase.auth().signInWithPopup(provider)
  },

  signOut() {
    return firebase.auth().signOut()
  },

  fetchMessages() {
    return firebase.firestore().collection('messages')
      .orderBy('created', 'desc')
      .get().then(function (snapshot) {
        const messages = []
        snapshot.forEach(function (doc) {
          messages.push({ id: doc.id, ...(doc.data()) })
        })
        return messages
      })
  },

  deleteMessages(ids) {
    const batch = firebase.firestore().batch()
    ids.forEach(id => {
      const docRef = firebase.firestore().collection('messages').doc(id)
      batch.delete(docRef)
    })
    return batch.commit()
  },

}
