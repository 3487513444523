import {
  FETCHING_MESSAGES, FETCH_MESSAGES_FAILED, FETCH_MESSAGES_SUCCESSFUL, CLEAR_MESSAGES,
} from '../actions/messages'

const initState = {
  fetching: false,
  messages: [],
  error: null,
}

export function fetchMessages(state = initState, action) {
  switch (action.type) {
    case FETCHING_MESSAGES:
      return Object.assign({}, state, {
        fetching: true,
      })
    case FETCH_MESSAGES_FAILED:
      return Object.assign({}, state, {
        fetching: false,
        error: action.error,
      })
    case FETCH_MESSAGES_SUCCESSFUL:
      return Object.assign({}, state, {
        fetching: false,
        messages: action.messages,
      })
    case CLEAR_MESSAGES:
      return initState
    default:
      return state
  }
}
