import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { saveMessageState } from '../contact/reducers'
// import signInWithGoogle from '../auth/reducers'
import { fetchMessages } from '../admin/messages/reducers'

const rootReducer = combineReducers({
  // ...your other reducers here
  // you have to pass formReducer under 'form' key,
  // for custom keys look up the docs for 'getFormState'
  form: formReducer,
  saveMessageState,
  fetchMessages,
  // signInWithGoogle
})

export default rootReducer
