export const FETCH_MESSAGES = 'FETCH_MESSAGES'
export const FETCHING_MESSAGES = 'FETCHING_MESSAGES'
export const FETCH_MESSAGES_FAILED = 'FETCH_MESSAGES_FAILED'
export const FETCH_MESSAGES_SUCCESSFUL = 'FETCH_MESSAGES_SUCCESSFUL'
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'
export const DELETE_MESSAGES = 'DELETE_MESSAGES'

export function fetchMessages() {
  return { type: FETCH_MESSAGES }
}

export function fetchingMessages() {
  return { type: FETCHING_MESSAGES }
}

export function fetchMessagesSuccessful(messages) {
  return { type: FETCH_MESSAGES_SUCCESSFUL, messages }
}

export function fetchMessagesFailed(error) {
  return { type: FETCH_MESSAGES_FAILED, error }
}

export function clearMessages() {
  return { type: CLEAR_MESSAGES }
}

export function deleteMessages(ids) {
  return { type: DELETE_MESSAGES, ids}
}
