import {
  SAVING_MESSAGE, SAVE_MESSAGE_FAILED, SAVE_MESSAGE_SUCCESSFUL, CLEAR_MESSAGE_STATE,
} from '../actions/message'

export function saveMessageState(state = {}, action) {
  switch (action.type) {
    case SAVING_MESSAGE:
      return Object.assign({}, state, {
        status: 'saving'
      })
    case SAVE_MESSAGE_FAILED:
      return Object.assign({}, state, {
        status: 'saveFailed',
        error: action.error
      })
    case SAVE_MESSAGE_SUCCESSFUL:
      return Object.assign({}, state, {
        status: 'saveSuccessful'
      })
    case CLEAR_MESSAGE_STATE:
      return ({})
    default:
      return state
  }
}
