import ContactPage from './ContactPage'
import { connect } from 'react-redux'
import { submitMessage, clearMessageState } from '../actions/message'
import { saveMessageStateSelector } from './selectors'

const mapStateToProps = (state, props) => {
  return {
    saveMessageState: saveMessageStateSelector(state),
  }
}

const mapDispatchToProps = { submitMessage, clearMessageState }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactPage)
