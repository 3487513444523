import { all } from 'redux-saga/effects'
import signInFlow from '../auth/sagas'
import history from '../router/history'
import watchSubmitMessage from '../contact/sagas'
import watchMessages from '../admin/messages/sagas'

export default function* rootSaga() {
  yield all([
    signInFlow(history),
    watchSubmitMessage(),
    watchMessages(),
  ])
}
