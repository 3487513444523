import axios from 'axios'

//const host = 'http://localhost:5001/unikang-dawn/us-central1'
const host = 'https://us-central1-unikang-dawn.cloudfunctions.net'
const saveMessageUrl = `${host}/unikang/addMessage`
const verifyCaptchaUrl = `${host}/unikang/checkRecaptcha`

export default {

  saveMessage(recaptchaToken, recaptchaAction, message) {
    return axios.post(saveMessageUrl, {
      recaptchaToken, recaptchaAction, content: message
    }).then(res => {
      console.log(res)
      return res.data
    })
  },

  verifyCaptcha(recaptchaToken) {
    return axios.post(verifyCaptchaUrl, {
      response: recaptchaToken
    }).then(res => {
      console.log(res)
      return res.data
    })
  },

}
