import SignInPage from './SignInPage'
import { connect } from 'react-redux'
import { signIn } from '../actions/auth'

const mapStateToProps = (state /*, ownProps*/) => {
  return {
  }
}

const mapDispatchToProps = { signIn }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInPage)
