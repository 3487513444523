import { call, put, throttle } from 'redux-saga/effects'
import cloudFuncApi from '../api/cloudFunctions'
import {
  SUBMIT_MESSAGE,
  savingMessage, saveMessageSuccessful, saveMessageFailed,
} from '../actions/message'

function* submitMessage(action) {
  try {
    yield put(savingMessage())
    const { recaptchaToken, recaptchaAction, message } = action
    const result = yield call(cloudFuncApi.saveMessage, recaptchaToken, recaptchaAction, message)
    if (result && result.success) {
      yield put(saveMessageSuccessful())
    } else {
      yield put(saveMessageFailed(result && result.error))
    }
  } catch (error) {
    yield put(saveMessageFailed(error.message))
  }
}

export default function* watch() {
  yield throttle(2000, SUBMIT_MESSAGE, submitMessage)
}
