import React from 'react'
import { Field, reduxForm } from 'redux-form'
import MuiTextField from '../ui/MuiTextField'
import Button from '@material-ui/core/Button'
import { required, minLength, maxLength, email } from '../ui/validations'

const minLength2 = minLength(2)
const minLength10 = minLength(10)
const maxLength20 = maxLength(20)
const maxLength50 = maxLength(50)
const maxLength400 = maxLength(400)

const phoneNumber = value =>
      value && !/^(0|[1-9])[0-9]{9}$/i.test(value)
        ? 'Invalid phone number, must be 10 digits'
        : undefined

let ContactForm = ({ enableSubmit, handleSubmit, isSubmitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field
            name="name"
            component={MuiTextField}
            label="Name"
            validate={[required, minLength2, maxLength20]}
            margin="normal"
            fullWidth
            disabled={isSubmitting}
          />
      </div>
      <div>
        <Field
            name="phone"
            component={MuiTextField}
            label="Phone Number"
            validate={[required, phoneNumber]}
            margin="normal"
            fullWidth
            disabled={isSubmitting}
          />
      </div>
      <div>
        <Field
            name="email"
            component={MuiTextField}
            label="Email"
            validate={[required, maxLength50, email]}
            margin="normal"
            fullWidth
            disabled={isSubmitting}
          />
      </div>
      <div>
        <Field
            name="message"
            label="Please leave us a message"
            component={MuiTextField}
            multiline
            rows="8"
            validate={[required, minLength10, maxLength400]}
            margin="normal"
            variant="outlined"
            className="textarea"
            disabled={isSubmitting}
          />
      </div>
      <div>
        <Button type="submit" variant="contained" color="primary" disabled={!enableSubmit || isSubmitting}>Submit</Button>
      </div>
    </form>
  )
}

ContactForm = reduxForm({
  // a unique name for the form
  form: 'contact'
})(ContactForm)

export default ContactForm
