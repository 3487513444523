import React from 'react'
import MUIDataTable from "mui-datatables"
import Paper from '@material-ui/core/Paper'
import MuiAlert from '../../ui/MuiAlert'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  spacing: {
    margin: '40px',
  },
  tableHeader: {
    backgroundColor: '#fafafa',
  },
  flexRight: {
    '& span': {
      justifyContent: 'flex-end',
    }
  },
  textRight: {
    textAlign: 'right'
  },
}))

const MessageList = ({ messages, deleteMessages }) => {
  const classes = useStyles()

  const [selectedIds, setSelectedIds] = React.useState([])

  const [alert, setAlert] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const handleClose = (agreed) => {
    setOpen(false)

    if(agreed) {
      deleteMessages(selectedIds)
    }
  }

  const options = {
    filterType: 'checkbox',
    onRowsDelete: (row) => {
      const ids = row.data.map(selected => messages[selected.dataIndex].id)
      deleteMessages(ids)
      return true

      // setSelectedIds(ids)
      // setAlert(`Are you sure to delete the selected ${row.data.length} message(s)?`)
      // setOpen(true)
      // return false
    },
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },
    responsive: "scrollMaxHeight"
  }

  const columns = [
    { name: 'name', label: 'Name' },
    { name: 'email', label: 'Email' },
    { name: 'phone', label: 'Phone' },
    {
      name: 'message',
      label: 'Message',
      options: {
        setCellHeaderProps: (value) => ({
          className: classnames({
            [classes.flexRight]: true
          })
        }),
        setCellProps: (value) => ({
          className: classnames({
            [classes.textRight]: true
          })
        }),
      }
    },
  ]

  return (
    <Paper className={classes.spacing}>
      <MUIDataTable
        title={"Messages"}
        data={messages}
        columns={columns}
        options={options}
      />

      <MuiAlert text={alert} open={open} handleClose={handleClose} />
    </Paper>
  )
}

export default MessageList
