import React from 'react'
import Header from './Header'
import Messages from './messages'
import withAuthentication from '../session/withAuthentication'

const AdminPage = withAuthentication(({ signOut }) => {

  return (
    <div>
      <Header signOut={signOut} />
      <Messages />
    </div>
  )

})

export default AdminPage
