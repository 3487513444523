import { take, call, put, cancelled, fork, cancel } from 'redux-saga/effects'
import firebaseApi from '../api/firebase'
import {
  SIGN_IN, SIGN_OUT, SIGN_IN_ERROR,
  signInError
} from '../actions/auth'

import {
  clearMessages
} from '../admin/actions/messages'

export default function* signInFlow(history) {
  while (true) {
    const signInOut = yield take([SIGN_IN, SIGN_OUT])
    let message = ''
    if (signInOut.type === SIGN_IN) {
      // fork return a Task object
      const task = yield fork(authorize, history)
      const action = yield take([SIGN_OUT, SIGN_IN_ERROR])
      if (action.type === SIGN_OUT) {
        yield cancel(task)
        yield call(signOut)
        message = "signed out"
        console.log('signed out 1')
      } else {
        message = action.error
      }
    } else {
      yield call(signOut)
      message = "signed out"
      console.log('signed out 2')
    }

    history.replace("/signin?message=" + message)
  }
}

function* signOut() {
  yield call(firebaseApi.signOut)
  yield put(clearMessages())
}

function* authorize(history) {
  try {
    yield call(firebaseApi.signInGoogle)
    history.replace('/admin')
  } catch(error) {
    console.log(error)
    yield put(signInError(error))
  } finally {
    if (yield cancelled()) {
      console.log('signIn is cancelled')
    }
  }
}
