import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import AuthUserContext from '../session/context'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
  },
}))

const Header = ({ signOut }) => {
  const classes = useStyles()
  return (

      <AuthUserContext.Consumer>
      { authUser => (
          <div className={classes.root}>
            <AppBar position="static">
              <Toolbar>
                <Typography variant="h6" className={classes.title}>
                  { authUser.displayName }
                </Typography>

                <Button
                  onClick={() => signOut()}
                  color="inherit"
                  endIcon={
                    <SvgIcon>
                      <path fill="#ffffff" d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z" />
                    </SvgIcon>
                  }
                >
                  Sign Out
                </Button>
              </Toolbar>
            </AppBar>
          </div>
        )
      }
      </AuthUserContext.Consumer>
  )
}

export default Header
