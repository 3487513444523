import React from 'react';
import { Provider } from 'react-redux'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import './App.css';
import ContactPage from './contact'
import SignInPage from './auth'
import AdminPage from './admin'
import store from './store'
import history from './router/history'
import firebase, { FirebaseContext } from './firebase'

import {
  Router,
  Switch,
  Route
} from "react-router-dom"

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <Router history={history}>
          <MuiThemeProvider>
            <Switch>
              <Route path="/admin">
                <FirebaseContext.Provider value={firebase}>
                  <AdminPage />
                </FirebaseContext.Provider>
              </Route>
              <Route path="/signin">
                  <SignInPage />
              </Route>
              <Route path="/">
                <FirebaseContext.Provider value={firebase}>
                  <ContactPage recaptchaAction="contact" />
                </FirebaseContext.Provider>
              </Route>
            </Switch>
          </MuiThemeProvider>
        </Router>
      </Provider>
    </div>
  )
}

export default App
