import React from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import ContactForm from './ContactForm'
import ReCaptcha from '../recaptcha/ReCaptcha'
import MuiLoading from '../ui/MuiLoading'
import MuiSnackbar from '../ui/MuiSnackbar'

class ContactPage extends React.Component {

  clearError(event, reason) {
    if (reason === 'clickaway') return

    const { clearMessageState } = this.props
    clearMessageState()
  }

  submittingMessage() {
    const { saveMessageState } = this.props
    if (saveMessageState && saveMessageState.status === 'saving') {
      return "Sending message..."
    }
  }

  errorMessage() {
    const { saveMessageState } = this.props
    return saveMessageState && saveMessageState.error
  }

  async submit(executeCaptcha, message) {
    const { submitMessage, recaptchaAction } = this.props
    const recaptchaToken = await executeCaptcha()
    submitMessage(recaptchaToken, recaptchaAction, message)
  }

  render() {
    const { saveMessageState, recaptchaAction } = this.props
    return (
      <Container maxWidth="sm">
        <h1>UniKang</h1>
        { saveMessageState && saveMessageState.status === 'saveSuccessful' ?
          <Box>
            <div>We received your message, thank you!</div>
            <div>We will get back to ASAP.</div>
          </Box>
          : <ReCaptcha action={recaptchaAction}>
            {({ isReady, execute }) => (
              <ContactForm
                enableSubmit={isReady}
                onSubmit={(message) => this.submit(execute, message)}
                isSubmitting={!!this.submittingMessage()}
              />
            )}
            </ReCaptcha>
        }

        { this.submittingMessage() ?
          <MuiLoading text={this.submittingMessage()} />
          : null
        }

        <MuiSnackbar
          message={this.errorMessage()}
          open={!!this.errorMessage()}
          handleClose={(event, reason) => this.clearError(event, reason)}
        />
      </Container>
    )
  }

}

export default ContactPage
