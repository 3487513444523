import React from 'react'
import Box from '@material-ui/core/Box'
//import { makeStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  error: {
    padding: '20px',
    margin: '20px',
    fontWeight: 700,
  },
  color: {
    color: props => props.color,
  },
})

const MuiInfo = (props) => {
  const classes = useStyles(props)
  return (
    <Box className={`${classes.color} ${classes.error}`}>
      {props.text}
    </Box>
  )
}

export default MuiInfo
