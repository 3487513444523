import MessagesComponent from './MessagesComponent'
import { connect } from 'react-redux'
import { fetchMessages, deleteMessages } from '../actions/messages'
import {
  messagesSelector, fetchingMessagesSelector, fetchMessagesErrorSelector
} from './selectors'

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    messages: messagesSelector(state),
    fetchingMessages: fetchingMessagesSelector(state),
    fetchMessagesError: fetchMessagesErrorSelector(state),
  }
}

const mapDispatchToProps = { fetchMessages, deleteMessages }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesComponent)
