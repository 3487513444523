import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  withSpace: {
    padding: '20px',
    margin: '20px',
  },
  text: {
    marginTop: '10px',
  },
}))

const MuiLoading = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.withSpace}>
      <CircularProgress />
      { text ?
        <div className={classes.text}>{text}</div>
        : null
      }
    </div>
  )
}

export default MuiLoading
