import React, { Component } from 'react'
import MessageList from './MessageList'
import MuiLoading from '../../ui/MuiLoading'
import MuiInfo from '../../ui/MuiInfo'

class MessagesComponent extends Component {

  componentDidMount() {
    const { fetchMessages } = this.props
    fetchMessages()
  }

  render() {
    const { messages, fetchingMessages, fetchMessagesError, deleteMessages } = this.props

    return (
      <div>
        { fetchingMessages ?
          <MuiLoading />
          : <MessageList { ...{ messages, deleteMessages } } />
        }

        <MuiInfo text={fetchMessagesError} color="red" />
      </div>
    )
  }

}

export default MessagesComponent
