export const SIGN_IN = 'SIGN_IN'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'
export const SIGN_OUT = 'SIGN_OUT'

export function signIn() {
  return { type: SIGN_IN }
}

export function signInError(error) {
  return { type: SIGN_IN_ERROR, error }
}

export function signOut() {
  return { type: SIGN_OUT }
}
