import { createSelector } from 'reselect'

const fetchMessages = (state) => state.fetchMessages

export const messagesSelector = createSelector(
  fetchMessages,
  fetchMessages => fetchMessages.messages
)

export const fetchingMessagesSelector = createSelector(
  fetchMessages,
  fetchMessages => fetchMessages.fetching
)

export const fetchMessagesErrorSelector = createSelector(
  fetchMessages,
  fetchMessages => fetchMessages.error
)
