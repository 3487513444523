import AdminPage from './AdminPage'
import { connect } from 'react-redux'
import { signOut } from '../actions/auth'

const mapStateToProps = (state /*, ownProps*/) => {
  return {
  }
}

const mapDispatchToProps = { signOut }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPage)
